import React, { useState, useEffect } from 'react';

function Privacy() {	

  const  modalOpen = false;

  return (
    <div className="App">
	  개인정보 취급방침<br/>
<br/>
<br/>
* 1. 총칙<br/>
<br/>
비앤비디는(이하 ‘회사’라고 합니다)는 기프트월렛을 이용하는 회원의 개인정보 보호를 소중하게 생각하고, 회원의 개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있습니다.<br/>
<br/>
회사는 개인정보 보호 관련 주요 법률인 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”이라고 합니다)을 비롯한 모든 개인정보 보호에 관련 법률 규정 및 국가기관 등이 제정한 고시, 훈령, 지침 등을 준수합니다.<br/>
<br/>
본 개인정보 처리방침은 기프트월렛을 이용하는 회원에 대하여 적용되며, 회원이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 회사가 개인정보 보호를 위하여 어떠한 조치를 취하고 있는지 알립니다.<br/>
<br/>
* 2. 개인정보의 수집•이용에 대한 동의<br/>
<br/>
회사는 적법하고 공정한 방법에 의하여 서비스 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집하며 회원의 개인 식별이 가능한 개인정보를 수집하기 위하여 회원가입 시 개인정보수집•이용 동의에 대한 내용을 제공하고 회원이 '동의' 버튼을 클릭하면 개인정보 수집•이용에 대해 동의한 것으로 봅니다.<br/>
<br/>
* 3. 수집하는 개인정보의 항목<br/>
<br/>
    * 1. 계정정보<br/>
<br/>
        * 서비스 제공 시 회원 식별을 위해 회원의 ID(이메일), 비밀번호를 저장합니다.<br/>
<br/>
    * 2. 쿠폰, 멤버십 사진<br/>
<br/>
        * 기프트월렛의 모바일 지갑 서비스를 제공하기 위해 회원의 디바이스 로컬 저장공간에 저장된 제3자 앱의 사진을 저장합니다.<br/>
<br/>
    * 3. 디바이스 정보<br/>
<br/>
        * 회원에게 맞춰진 정보를 제공하기 위해 회원의 디바이스 정보(모델명, 타입, 운영체제 및 버전 등), 회원 언어설정, 모바일 네트워크 및 인터넷 서비스 제공업체, 디바이스 고유 ID를 저장합니다.<br/>
<br/>
    * 4. 사용기록 및 로그데이터 등<br/>
<br/>
        * 더 나은 서비스 경험제공과 서비스 부정 이용 방지를 위해 회원의 서비스 이용 기록, 엑세스 시간, 회원이 클릭하거나 스크랩한 광고내역 정보 및 기타 로그데이터를 저장합니다.<br/>
<br/>
* 4. 개인정보의 수집방법<br/>
<br/>
회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
<br/>
    * 1. 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집<br/>
<br/>
    * 2. 회원의 선택에 따라 카카오톡 아이디를 이용하여 로그인하는 회원의 경우 해당 협력회사로부터 자동으로 수집<br/>
<br/>
    * 3. 회원의 선택에 따라 디바이스 로컬저장공간으로부터 수집<br/>
<br/>
    * 4. 로그정보 수집 툴을 통한 수집<br/>
<br/>
* 5. 개인정보의 수집목적 및 이용목적<br/>
<br/>
회사는 수집한 개인정보를 다음의 목적으로 활용합니다. 단, 회사는 회원으로부터 영리목적 광고성 정보 수신에 대한 별도 동의를 받은 경우에 한해 회원에게 영리목적 광고성 정보를 발송합니다.<br/>
<br/>
    * 1. 회원관리<br/>
<br/>
        * 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 중복가입확인, 가입의사 확인, 연령확인, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달<br/>
<br/>
    * 2. 서비스 개선<br/>
<br/>
        * 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공, 서비스 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계<br/>
<br/>
    * 3. 마케팅 및 광고에의 활용<br/>
<br/>
        * 맞춤 이벤트 및 광고성 정보와 참여기회 제공.<br/>
<br/>
* 6. 개인정보의 제3자 제공<br/>
<br/>
    * 1. 회사는 회원의 개인정보를 본 개인정보 처리방침에서 명시된 범위를 초과하여 이용하거나 제 3자에게 제공하지 않습니다. 다만, 회원의 동의가 있거나 다음 각호의 어느 하나에 해당하는 경우에는 예외로 합니다.<br/>
<br/>
        * a. 서비스 제공에 따른 요금 정산을 위하여 필요한 경우<br/>
<br/>
        * b. 관계법령에 의하여 수사, 재판 또는 행정상의 목적으로 관계기관으로부터 요구가 있을 경우<br/>
<br/>
        * c. 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 가공하여 제공하는 경우<br/>
<br/>
        * d. 법령에서 정한 절차에 따른 요청이 있는 경우<br/>
<br/>
    * 2. 영업의 전부 또는 일부를 양도하거나, 합병/상속 등으로 서비스제공자의 권리, 의무를 이전 승계하는 경우 개인 정보보호 관련 회원의 권리를 보장하기 위하여 반드시 그 사실 및 개인정보의 이전을 원하지 아니하는 경우 그 동의를 철회할 수 있는 방법과 절차를 회원에게 통지합니다.<br/>
<br/>
    * 3. 보다 나은 서비스 제공을 위하여 회사가 회원의 개인정보를 타 기업 등 제3자에게 제공하는 것이 필요한 경우에 는 회사는 사전에 개인정보를 제공받는 자가 누구인지, 제공되는 개인정보항목이 무엇인지, 제공되는 개인정보의 이용목적, 그리고 언제까지 공유되며 어떻게 보호 관리되는지에 대하여 회원에게 고지하여 동의를 구하는 절차를 거칠 것이며, 회원이 동의하지 않는 정보는 제3자에 제공되지 않습니다.<br/>
<br/>
* 7. 개인정보의 보유 및 이용기간<br/>
<br/>
    * 1. 회원의 개인정보는 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 회원이 회원탈퇴를 하거나 개인정보 허위기재로 인해 회원 ID 삭제 처분을 받은 경우 수집된 개인정보는 완전히 삭제되며 어떠한 용도로도 이용할 수 없도록 처리됩니다. 다만, 도용으로 인한 원치 않는 회원탈퇴 등의 피해가 발생할 우려가 있으므로 탈퇴 신청 후 7일의 유예기간 동안 회사는 개인정보를 임시 보관하게 되며, 이는 이용 해지 시 회원에게 고지됩니다. 7일 후에 는 회사의 회원정보 데이터베이스(DB)에서 완전히 삭제됩니다.<br/>
<br/>
    * 2. 회원의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기되나, 아래 각 항목에 해당하는 경우에는 명시한 기간 동안 보관할 수 있으며, 그 외 다른 목적으로는 사용하지 않습니다.<br/>
<br/>
        * a. 부정이용기록 : 1년<br/>
<br/>
        * b. 계약 또는 청약철회 등에 관한 기록 : 5년<br/>
<br/>
        * c. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br/>
<br/>
        * d. 표시, 광고에 관한 기록 : 6개월<br/>
<br/>
        * e. 웹, 어플리케이션 방문기록 : 1년<br/>
<br/>
* 8. 개인정보의 파기절차 및 방법<br/>
<br/>
회사는 수집한 개인정보의 이용목적이 달성된 후 별도의 DB로 옮겨져 보관기간 및 이용기간에 따라 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br/>
<br/>
    * 1. 파기절차<br/>
<br/>
        * 회원이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정기간(개인정보 보관기간 및 이용기간 참조) 동안 저장 보관된 후 삭제되거나 파기합니다.<br/>
<br/>
    * 2. 파기방법<br/>
<br/>
        * 서면양식에 기재하거나, 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기하고, 전자적 파일형태로 저장된 개인 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
<br/>
* 9. 회원 및 법정대리인의 권리와 행사방법<br/>
<br/>
    * 1. 회원 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.<br/>
<br/>
    * 2. 회원 혹은 만 14세 미만 아동의 개인정보 조회/수정을 위해서는 ‘개인정보변경’(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br/>
<br/>
    * 3. 회원이 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
<br/>
    * 4. 회사는 회원 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "7. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
<br/>
* 11. 회원의 의무<br/>
<br/>
회원은 본인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해주시기 바랍니다. 회원이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 회원 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력 할 경우 계정의 이용이 제한될 수 있습니다.<br/>
<br/>
회사가 운영하는 서비스를 이용하는 회원은 개인정보를 보호 받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 회원은 아이디(ID), 비밀번호를 포함한 개인정보가 유출되지 않도록 조심 하여야 하며, 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 타인의 존엄성을 훼손할 경우에는 정보통신망법 등에 의해 처벌 받을 수 있습니다.<br/>
<br/>
* 12. 개인정보의 기술적/관리적 기술대책<br/>
<br/>
회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/>
<br/>
    * 1. 해킹 등에 대비한 대책<br/>
<br/>
        * 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
<br/>
    * 2. 개인정보 처리직원의 최소화 및 교육<br/>
<br/>
        * 회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보 처리방침의 준수를 항상 강조하고 있습니다<br/>
<br/>
        * 회사는 회사의 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 회사의 고의 또는 중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.<br/>
<br/>
* 12. 고지의 의무<br/>
<br/>
현 개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 시행일 최소 30일전부터 기프트월렛 어플을 통해 공고할 것입니다.<br/>
<br/>
* 14. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항<br/>
<br/>
    * 1. 쿠키의 수집<br/>
<br/>
        * 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 회원의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트 파일로서 회원 컴퓨터의 하드디스크에 저장됩니다. 회원이 웹 사이트에 방문할 경우 웹 사이트 서버는 회원의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 회원의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다. 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 회원은 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.<br/>
<br/>
    * 2. 쿠키의 설치/운영 및 거부 방법<br/>
<br/>
        * 회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 개인화된 맞춤추천 서비스 등 일부 서비스 이용에 어려움이 있을 수 있습니다.<br/>
<br/>
        * - 설정방법:<br/>
<br/>
        * 인터넷 익스플로어의 경우: 웹 브라우저 상단의 [도구] > [인터넷 옵션] > [개인정보] > [개인정보처리 수준] 설정<br/>
<br/>
* 15. 개인정보관리 책임자 및 담당자<br/>
<br/>
회사는 회원의 개인정보보호를 가장 중요시하며, 회원의 개인정보가 훼손, 침해 또는 누설되지 않도록 최선을 다하고 있습니다. 서비스를 이용하시면서 발생하는 모든 개인정보보호 관련 민원을 고객센터에 신고하시면 신속하게 답변해드리도록 하겠습니다.<br/>
<br/>
    * 1. 개인정보관리책임자<br/>
<br/>
        * 성 명 : 박종탁<br/>
<br/>
        * 직 위 : 대표이사<br/>
<br/>
        * 전자우편 : sosim13p@gmail.com
<br/><br/><br/><br/><br/>
    </div>
  );
}

export default Privacy;
